import React, { useState } from 'react';
import { Collapse, Popover, Button } from 'antd';
import { CustomerServiceFilled } from '@ant-design/icons';

const { Panel } = Collapse;

// Feedback 문자열
const MapSuggestionText = () => {
  return (
    <>
      <span>
        If you have any suggestions or issues, please send an email to <a href="mailto:gil@novonetworks.com?subject=[MapViewer]">HERE</a>
      </span>
      <br />
      <span>Thanks for your comment.</span>
    </>
  );
};

// Feedback
const MapFeedback = () => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover className="map-feedback" title="Feedback" content={<MapSuggestionText />} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
      <Button type="ghost" style={{ position: 'fixed', top: 10, right: 10 }} icon={<CustomerServiceFilled />}></Button>
    </Popover>
  );
};

const MapHeaderTitle = () => {
  return <span className="map-header-title">GCC Map Viewer</span>;
};

// Map View Header
const MapHeader = (props: any) => {
  return (
    <Collapse>
      <Panel header={<MapHeaderTitle />} key="1" id="map-header-title">
        {props.text}
      </Panel>
    </Collapse>
  );
};

export { MapHeader, MapFeedback };
