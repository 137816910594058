import React from 'react';
import { Upload, Button, Tooltip, message } from 'antd';
import { InboxOutlined, DatabaseFilled } from '@ant-design/icons';

const { Dragger } = Upload;

const validMapFile = (file: any, filelist: any) => {
  let valid = false;
  let fileName = file.name;

  valid = fileName.split('.').pop() === 'map';

  if (!valid) {
    message.error(`Invalid map file [ ${file.name} ]`);
  }
  return valid;
};

const MapUpload = function (props: any) {
  const uploadFile = {
    name: 'file',
    progress: {
      strokeWidth: 2,
      showInfo: true,
    },
    multiple: false,
    action: '/api/fileUpload',
    beforeUpload: validMapFile,
    onChange(info: any) {
      const { status, response } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} File uploaded successfully.`);
        props.onLoad({
          sample: false,
          orgFile: info.file.name,
          file: response.file,
        });
      } else if (status === 'error') {
        message.error(`${info.file.name} File upload failed.`);
      }
    },
  };

  const handleSampleClick = () => props.onLoad({ sample: true, orgFile: 'Sample', file: 'sample' });

  return (
    <>
      <Dragger {...uploadFile}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag gcc linker map file (*.map) to this area to upload</p>
        <p className="ant-upload-hint">A map file can be generated by passing the "-Wl,-Map=output.map" to Linker option.</p>
        <p className="ant-upload-hint">In addition the "-Wl,--cref" option will generate the cross-reference table.</p>
        <br />
      </Dragger>
      <br />
      <Tooltip placement="top" title="Load sample map file">
        <Button type="dashed" block size="large" icon={<DatabaseFilled />} onClick={handleSampleClick}>
          Sample
        </Button>
      </Tooltip>
    </>
  );
};

export default MapUpload;
