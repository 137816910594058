import React, { useState } from 'react';
import '../css/App.less';
import axios from 'axios';
import { Spin, Row, message } from 'antd';
import MapUpload from './MapUpload';
import { MapHeader, MapFeedback } from './MapHeader';
import MapTable from './MapTable';

// Map View States
enum VState {
  INIT,
  // LOADING,
  PROCESSING,
  LOADED,
}

const MainUpdateTitle = (subText: string) => {
  const MapViewerTitle = 'GCC Map Viewer';
  let htmlTitle = document.querySelector('title') as HTMLElement;

  htmlTitle.innerHTML = MapViewerTitle + ' : ' + subText;
};

// Spinner
const MainSpinner = () => {
  return (
    <>
      <br />
      <Row justify="center">
        <Spin tip="Processing ..." size="large"></Spin>
      </Row>
    </>
  );
};

const InitSubTitle = () => {
  return (
    <>
      <span>
        You can use a linker map to keep an eye on the placement of the object and the size of your writing code..
        <br />
        This Map Viewer will improve the readability of linker maps and bring you closer to better embedded software.
      </span>
    </>
  );
};

const TableSubTitle = () => {
  return (
    <>
      <span>
        The displayed table is the information analyzed and extracted from the uploaded linker map file.
        <br />
        You can enter the symbol or address as a filtering, and sort for each column.
      </span>
    </>
  );
};

let MainCalled = 0;

// MAIN View Render
const Main = () => {
  const [vState, setVState] = useState(VState.INIT);
  const [mapTableData, setMapTableData] = useState({ memory: null, section: null, symbol: null });

  MainCalled++;
  if (process.env.NODE_ENV !== 'production') {
    console.log('Main Called !!!: ', MainCalled);
  }

  const MapDataLoaded = (data: any) => {
    // add column id
    const mapSectData = (data && data.section).map((r: any) => ({ ...r, _id: r.section + ':' + r.address }));
    setMapTableData({ memory: data.memory, section: mapSectData, symbol: data.symbol });
  };

  // Upload Callback
  const handleOnLoad = (info: any) => {
    setVState(VState.PROCESSING);

    console.log(`loaded file: ${info.file}, ${info.orgFile}`);

    MainUpdateTitle(info.orgFile);

    // Map 파일 파싱 결과 요청
    const url = '/api/mapTable';
    axios
      .get(url, {
        params: {
          file: info.file,
        },
      })
      .then((response) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`response.data.result: ${response.data.result}`);
        }

        if (response.data.result === 'success') {
          // loaded data 저장
          MapDataLoaded(response.data.table);
          setVState(VState.LOADED);
        } else {
          message.error(`${info.orgFile} parsed failed`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Map Table 다시 로드
  const handleOnReload = () => {
    setVState(VState.INIT);
    setMapTableData({ memory: null, section: null, symbol: null });
  };

  // render
  switch (vState) {
    case VState.PROCESSING:
    case VState.LOADED:
      let spinner = null;

      if (vState === VState.PROCESSING) {
        spinner = <MainSpinner />;
      }
      return (
        <>
          <MapHeader text={<TableSubTitle />} />
          <MapFeedback />
          {spinner}
          <MapTable mapData={mapTableData} onReload={handleOnReload} />
        </>
      );

    case VState.INIT:
    default:
      return (
        <>
          <MapHeader text={<InitSubTitle />} />
          <MapFeedback />
          <MapUpload onLoad={handleOnLoad} />
        </>
      );
  }
};

export default Main;
