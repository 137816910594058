import React from 'react';
import { Tooltip } from 'antd';

const ShowMapMemorySize = (size: number) => {
  if (size === 0) return '0 Byte';

  const k: number = 1024;
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(size) / Math.log(k));

  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

/* ------------------- SYMBOL TABLE ------------------- */

// "symbol": [
//   {
//     "symbol": "app_preamble",
//     "section": ".preamble",
//     "address": "0x08040000",
//     "length": 512,
//     "location": "App/src/init.o"
//   },
//   {
//     "symbol": "g_pfnVectors",
//     "section": ".isr_vector",
//     "address": "0x08040200",
//     "length": 504,
//     "location": "Core/Startup/startup_stm32f767zitx.o"
//   },
// ]

let symbolColumns: any = [
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
    width: '15%',
    minWidth: 100,
    sorter: {
      compare: (a: any, b: any): number => {
        const aSym = a.symbol;
        const bSym = b.symbol;

        if (aSym > bSym) return 1;
        if (aSym < bSym) return -1;
        return 0;
      },
      multiple: 2,
    },
    showSorterTooltip: false,
    ellipsis: true,
  },
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
    width: '10%',
    minWidth: 80,
    filters: [
      {
        text: 'text',
        value: '.text',
      },
      {
        text: 'data',
        value: '.data',
      },
      {
        text: 'bss',
        value: '.bss',
      },
    ],
    // Issue : section, memory table일때 호출 ??
    onFilter: (value: string, record: any) => !record.symbol || (record.section && record.section.indexOf(value) === 0),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '10%',
    minWidth: 80,
    sorter: {
      compare: (a: any, b: any): number => {
        const aAddr = parseInt(a.address, 16);
        const bAddr = parseInt(b.address, 16);
        return aAddr - bAddr;
      },
      multiple: 3,
    },
    showSorterTooltip: false,
  },
  {
    title: 'Length',
    dataIndex: 'length',
    key: 'length',
    width: '10%',
    minWidth: 50,
    sorter: {
      compare: (a: any, b: any): number => {
        const aLen = parseInt(a.length, 10);
        const bLen = parseInt(b.length, 10);
        return aLen - bLen;
      },
      multiple: 1,
    },
    sortDirections: ['descend', 'ascend'],
    showSorterTooltip: false,
    render: (text: any, record: any) => {
      let title: string = '';
      let size = parseInt(text, 10);
      if (size >= 1024) {
        title = ShowMapMemorySize(size);
      }
      if (title) {
        return (
          <Tooltip placement="topLeft" title={title}>
            {text}
          </Tooltip>
        );
      } else {
        return <>{text}</>;
      }
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: '25%',
    minWidth: 100,
    ellipsis: true,
    sorter: {
      compare: (a: any, b: any): number => {
        const aLoc = a.location;
        const bLoc = b.location;

        if (aLoc > bLoc) return 1;
        if (aLoc < bLoc) return -1;
        return 0;
      },
      multiple: 2,
    },
    showSorterTooltip: false,
    render: (text: any, record: any) => {
      if (!text) return;
      let title = text;
      return (
        <Tooltip overlayStyle={{ minWidth: '30%' }} placement="topLeft" title={title}>
          {text}
        </Tooltip>
      );
    },
  },
  {
    title: 'Reference',
    dataIndex: 'ref',
    key: 'reference',
    width: '30%',
    minWidth: 160,
    ellipsis: true,
    render: (text: any, record: any) => {
      if (!text) return;
      let title = text.join('\n');
      let aText = text.join(', ');
      return (
        <Tooltip overlayStyle={{ whiteSpace: 'pre-wrap', minWidth: '29.5%' }} placement="topLeft" title={title}>
          {aText}
        </Tooltip>
      );
    },
  },
];

/* SECTION TABLE */

// "section": [
//   {
//     "section": ".preamble",
//     "address": "0x08040000",
//     "length": "0x00000200"
//   },
//   {
//     "section": ".isr_vector",
//     "address": "0x08040200",
//     "length": "0x000001F8"
//   },
// ]

let sectionColumns: any = [
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
    width: '50%',
    minWidth: 100,
    sorter: {
      compare: (a: any, b: any): number => {
        const aSec = a.section;
        const bSec = b.section;

        if (aSec > bSec) return 1;
        if (aSec < bSec) return -1;
        return 0;
      },
      multiple: 1,
    },
    showSorterTooltip: false,
    ellipsis: true,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '30%',
    minWidth: 80,
    sorter: {
      compare: (a: any, b: any): number => {
        const aAddr = parseInt(a.address, 16);
        const bAddr = parseInt(b.address, 16);
        return aAddr - bAddr;
      },
      multiple: 3,
    },
    showSorterTooltip: false,
  },
  {
    title: 'Length',
    dataIndex: 'length',
    key: 'length',
    width: '20%',
    minWidth: 50,
    sorter: {
      compare: (a: any, b: any): number => {
        const aLen = parseInt(a.length, 16);
        const bLen = parseInt(b.length, 16);
        return aLen - bLen;
      },
      multiple: 1,
    },
    sortDirections: ['descend', 'ascend'],
    showSorterTooltip: false,
    render: (text: any, record: any) => {
      let title: string = '';
      if (text) {
        title = ShowMapMemorySize(parseInt(text, 16));
      }
      return (
        <Tooltip style={{ whiteSpace: 'pre-wrap' }} placement="topLeft" title={title}>
          {text}
        </Tooltip>
      );
    },
  },
];

/* MEMORY CONFIGURATION */

// "memory": [
//   {
//     "name": "FLASH",
//     "origin": "0x08040000",
//     "length": "0x00040000",
//     "attr": "xr"
//   },
// ]
let memoryColumns: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
    minWidth: 100,
    sorter: {
      compare: (a: any, b: any): number => {
        const aSec = a.section;
        const bSec = b.section;

        if (aSec > bSec) return 1;
        if (aSec < bSec) return -1;
        return 0;
      },
      // multiple: 1,
    },
    showSorterTooltip: false,
    ellipsis: true,
    filterable: false,
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',
    width: '25%',
    minWidth: 80,
    sorter: {
      compare: (a: any, b: any): number => {
        const aAddr = parseInt(a.origin, 16);
        const bAddr = parseInt(b.origin, 16);
        return aAddr - bAddr;
      },
      // multiple: 1,
    },
    showSorterTooltip: false,
  },
  {
    title: 'Length',
    dataIndex: 'length',
    key: 'length',
    width: '15%',
    minWidth: 50,
    sorter: {
      compare: (a: any, b: any): number => {
        const aLen = parseInt(a.length, 16);
        const bLen = parseInt(b.length, 16);
        return aLen - bLen;
      },
      // multiple: 1,
    },
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
    render: (text: any, record: any) => {
      let title: string = '';
      if (text) {
        title = ShowMapMemorySize(parseInt(text, 16));
      }
      return (
        <Tooltip placement="topLeft" title={title}>
          {text}
        </Tooltip>
      );
    },
  },
  {
    title: 'Attribute',
    dataIndex: 'attr',
    key: 'attr',
    width: '10%',
    minWidth: 50,
    showSorterTooltip: false,
  },
];

const MapTableSchema: any = {
  symbol: {
    columns: symbolColumns,
    rowKey: 'symbol',
  },
  section: {
    columns: sectionColumns,
    rowKey: '_id', // section + address로 추가
  },
  memory: {
    columns: memoryColumns,
    rowKey: 'name',
  },
};

export { MapTableSchema, ShowMapMemorySize };
