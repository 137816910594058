import React, { useState, useEffect, useMemo } from 'react';
import '../css/App.less';
import '../css/MapTable.less';
import { Table, Input, Button, Checkbox, Row, Col, Tabs, Form, Tooltip, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { MapTableSchema, ShowMapMemorySize } from './MapTableSchema';

const { Search } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

// Map View Tabs
enum MapTabs {
  SYMBOL = 'symbol',
  SECTION = 'section',
  MEMORY = 'memory',
}

// global variables
const isHexaNumber = (value: string): boolean => {
  return /\b(0x|0X)?[0-9a-fA-F]+\b/.test(value);
};

// 검색에 의한 데이블 재생성 (Symbol Table only)
const MapFilteredData = (tabs: any, baseData: any, value: string, options: Array<string>) => {
  if (tabs !== MapTabs.SYMBOL) return;

  let resultData: any = {
    filterBy: 'symbol',
    info: '',
    data: null,
  };

  if (!baseData || value.length === 0) {
    return resultData;
  }

  const filteredByText = (baseData: any, value: string, options: Array<string>) => {
    let filteredData = [];
    let filterOptCS: boolean = options.indexOf('caseSensitive') !== -1;
    let filterOptEA: boolean = options.indexOf('exact') !== -1;

    if (baseData && value.length) {
      filteredData = baseData.filter((r: any) => {
        let matched: boolean;

        if (filterOptEA) {
          matched = r.symbol === value || r.address === value;
        } else if (filterOptCS) {
          matched = r.symbol.includes(value) || r.address.includes(value);
        } else {
          matched = r.symbol.toLowerCase().includes(value.toLowerCase()) || r.address.toLowerCase().includes(value.toLowerCase());
        }

        return matched;
      });
    }

    return filteredData;
  };

  const filteredByNumber = (baseData: any, value: number) => {
    let filteredData = [];
    let offset = 0;
    if (value) {
      filteredData = baseData.filter((r: any) => {
        let matched = false;
        let address = parseInt(r.address, 16);
        let length = parseInt(r.length, 10);

        matched = value >= address && value < address + length;
        if (matched) {
          offset = value - address;
        }

        return matched;
      });
    }

    if (filteredData && filteredData.length) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('offset : ', value - filteredData[0].address);
      }
    }
    return [filteredData, offset];
  };

  let filteredNumbers = [];
  let filterBy = 'symbol';
  let filteredData = filteredByText(baseData, value, options); // filter <- symbol + address

  if (isHexaNumber(value)) {
    filterBy = 'address';
    [filteredNumbers, resultData.info] = filteredByNumber(baseData, parseInt(value, 16));
  }

  // unique data로 생성
  filteredData = filteredData.concat(filteredNumbers.filter((item: any) => filteredData.indexOf(item) < 0));
  if (filterBy === 'address' && filteredData.length === 1) {
    filterBy = 'address';
  } else {
    filterBy = 'symbol';
  }

  resultData.filterBy = filterBy;
  resultData.data = filteredData;

  return resultData;
};

// Tabs = { Symbol Table | Section Table | Memory Configuration}
const MapTableTabs = (props: any) => {
  const loadButton = (
    <Button type="ghost" icon={<UploadOutlined />} style={{ width: 160, fontWeight: 500 }} ghost size="large" onClick={props.onLoadClick}>
      Upload
    </Button>
  );

  return (
    <Tabs type="card" id="mapTable-tabs" size="large" tabBarExtraContent={loadButton} onChange={props.onTabsChange}>
      <TabPane tab="Symbol Table" key="symbol"></TabPane>
      <TabPane tab="Section Table" key="section"></TabPane>
      <TabPane tab="Memory Configuration" key="memory"></TabPane>
    </Tabs>
  );
};

// 검색바 (Symbol Table only)
const MapTableSearch = (props: any) => {
  return (
    <Row align="middle">
      <Col span={18}>
        <Search placeholder="Enter symbol or address" allowClear size="large" value={props.search} onChange={props.onSearch} />
      </Col>
      <Col span={5} offset={1}>
        {props.searchOptsShow ? (
          <Checkbox.Group value={props.searchOpts} onChange={props.onSearchOptsChange}>
            <Checkbox value="caseSensitive">Case sensitive</Checkbox>
            <Checkbox value="exact">Whole word</Checkbox>
          </Checkbox.Group>
        ) : (
          <Form.Item style={{ marginBottom: 0 }} label="Offset">
            <Input style={{ color: 'white' }} disabled value={props.offsetValue}></Input>
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

/* filtered & search summary */
const MapTableSummary = (tabs: any, pageData: any, tableData: any, filteredData: any) => {
  if (tabs !== MapTabs.SYMBOL) return null;
  let pageTotal = 0;
  let tableTotal = 0;
  let pageCount = 0;
  let tableCount = 0;

  pageData.forEach((r: any) => {
    pageTotal += r.length;
    pageCount++;
  });

  tableData &&
    tableData.forEach((r: any) => {
      if (filteredData.length > 0) {
        filteredData.some((v: any) => {
          if (v === r) {
            tableTotal += r.length;
            tableCount++;
            return true; // break
          }
          return false; // continue
        });
      } else {
        tableTotal += r.length;
        tableCount++;
      }
    });

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2} className="text-center">
          Total (Count, Size)
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          <Text type="success">{pageCount}</Text>
          <Text type="success"> / </Text>
          <Text type="success">{tableCount}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <Tooltip placement="topLeft" title={ShowMapMemorySize(pageTotal)}>
            <Text type="success">{pageTotal}</Text>
          </Tooltip>
          <Text type="success"> / </Text>
          <Tooltip placement="topLeft" title={ShowMapMemorySize(tableTotal)}>
            <Text type="success">{tableTotal}</Text>
          </Tooltip>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

// Map Table View Render
const MapTable = (props: any) => {
  const [curTabs, setCurTabs] = useState(MapTabs.SYMBOL);
  const [baseMapData, setBaseMapData] = useState(props.mapData && props.mapData.symbol);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState(MapTableSchema.symbol.columns);
  const [rowKey, setRowKey] = useState(MapTableSchema.symbol.rowKey);
  const [search, setSearch] = useState('');
  const [searchOpts, setsSearchOpts] = useState(['']);
  const filteredMap = useMemo(() => MapFilteredData(curTabs, baseMapData, search, searchOpts), [curTabs, baseMapData, search, searchOpts]);

  useEffect(() => {
    setBaseMapData(props.mapData && props.mapData.symbol);
  }, [props.mapData]);

  // MapData 이동
  const tableDataSet = (tab: MapTabs) => {
    if (!baseMapData) return;

    console.log(`tabs changed ${tab}`);

    switch (tab) {
      case 'symbol':
        setColumns(MapTableSchema.symbol.columns);
        setRowKey(MapTableSchema.symbol.rowKey);
        setBaseMapData(props.mapData.symbol);
        break;
      case 'section':
        setColumns(MapTableSchema.section.columns);
        setRowKey(MapTableSchema.section.rowKey);
        setBaseMapData(props.mapData.section);
        break;
      case 'memory':
        setColumns(MapTableSchema.memory.columns);
        setRowKey(MapTableSchema.memory.rowKey);
        setBaseMapData(props.mapData.memory);
        break;
      default:
        return;
    }

    setCurTabs(tab);
  };

  // Map Table 다시 로드
  const handleLoadClick = () => {
    setSearch(''); //
    setsSearchOpts([]);
    props.onReload();
  };

  // Tab 클릭
  const handleTabChange = (activeKey: string) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`changed: ${activeKey}`);
    }
    tableDataSet(activeKey as MapTabs);
  };

  // search 입력
  const handleOnSearch = (e: any) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`enter value : ${e.target.value}`);
    }
    setSearch(e.target.value);
  };

  const handleSearchOptsChange = (checkedValues: any) => {
    setsSearchOpts(checkedValues as Array<string>);
  };

  const handleOnChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any }) => {
    console.log('table change');
    setFilteredData(extra['currentDataSource']);
  };

  const tableData = filteredMap && filteredMap.data ? filteredMap.data : baseMapData;
  const defaultPageSize = 20;
  const showPageSelect = !!(tableData && tableData.length > defaultPageSize);

  // render
  return (
    <>
      <MapTableTabs onTabsChange={handleTabChange} onLoadClick={handleLoadClick}></MapTableTabs>
      {curTabs === MapTabs.SYMBOL && (
        <MapTableSearch
          search={search}
          onSearch={handleOnSearch}
          searchOptsShow={!filteredMap || filteredMap.filterBy === MapTabs.SYMBOL}
          searchOpts={searchOpts}
          offsetValue={filteredMap && filteredMap.info}
          onSearchOptsChange={handleSearchOptsChange}
        ></MapTableSearch>
      )}
      <Table
        bordered
        columns={columns}
        dataSource={tableData}
        rowKey={rowKey}
        onChange={handleOnChange}
        summary={(pageData) => MapTableSummary(curTabs, pageData, tableData, filteredData)}
        pagination={{ defaultPageSize: defaultPageSize, showSizeChanger: showPageSelect }}
      />
    </>
  );
};

export default MapTable;
